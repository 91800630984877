import React, { useState } from "react";
import SliderItem from "../../components/atoms/SliderItem/SliderItem";

import "pure-react-carousel/dist/react-carousel.es.css";

import WipeBtnBlack from "../../components/atoms/buttons/WipeBtnBlack";
import { useLanguage } from "../../languageContext";
import CustomButtons from "../../molecules/homepage/homepageAboutSlider/CustomButtons";
import leftArren from "../../images/servicesassets/leftArrow-en.png";
import leftArrin from "../../images/servicesassets/leftArrow-in.png";
import leftArrja from "../../images/servicesassets/left-Arrow-ja.png";

import rightArren from "../../images/servicesassets/rightArrow-en.png";
import rightArrin from "../../images/servicesassets/rightArrow-in.png";
import rightArrja from "../../images/servicesassets/right-Arrow-ja.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

//slider top images

const IndustrySlider = ({
  SliderItems,
  heading,
  hideButtons,
  hideDefaultTitle,
  customTitle,
  hideExplore,
  hideUrl,
}) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 10000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const stopSliderHandler = () => {
    setIsPlaying(false);
  };
  const startSliderHandler = () => {
    setIsPlaying(true);
  };
  const [isPlaying, setIsPlaying] = useState(true);
  const currentLanguage = useLanguage();
  return (
    <div className="section section-br p-top-3em p-bottom-3em bg-light-blue">
      <div className="container">
        <div className="industry-slider common-bottom-space">
          <div className="heading m-top-20px">
            {hideDefaultTitle ? (
              <h2 className="heading no-margin">{customTitle}</h2>
            ) : (
              <h2 className="title-container heading heading-bottom-space">
                Our services for the{" "}
                {
                  <span
                    className={`color-${
                      currentLanguage ? currentLanguage : "en"
                    }`}
                  >
                    {heading} industry
                  </span>
                }{" "}
              </h2>
            )}
          </div>
          <div
            className="industry-slider-wrapper common-bottom-space"
            onMouseDown={stopSliderHandler}
            onMouseEnter={stopSliderHandler}
            onMouseLeave={startSliderHandler}
          >
            <Carousel
              responsive={responsive}
              arrows={false}
              renderButtonGroupOutside={true}
              autoPlaySpeed={3000}
              autoPlay={true}
              pauseOnHover={true}
              shouldResetAutoplay={true}
              customButtonGroup={
                <CustomButtons
                  leftArr={
                    currentLanguage === "en"
                      ? leftArren
                      : currentLanguage === "in"
                      ? leftArrin
                      : currentLanguage === "ja"
                      ? leftArrja
                      : leftArren
                  }
                  rightArr={
                    currentLanguage === "en"
                      ? rightArren
                      : currentLanguage === "in"
                      ? rightArrin
                      : currentLanguage === "ja"
                      ? rightArrja
                      : rightArren
                  }
                />
              }
              infinite={true}
              // autoPlaySpeed={1500}
            >
              {SliderItems.map((slideItem, index) => (
                <SliderItem
                  heading={slideItem.slideHeading}
                  tagline={slideItem.paragraph}
                  listItems={slideItem.listItems}
                  isUrl={
                    slideItem.url !== null
                      ? hideUrl !== undefined
                        ? hideUrl
                          ? false
                          : true
                        : true
                      : false
                  }
                  url={`${slideItem.url !== "#" ? "/" + slideItem.url : ""}`}
                  styleBullets={true}
                  sliderImage={
                    slideItem.slideImage
                      ? slideItem.slideImage.url
                      : "https://images.unsplash.com/photo-1593642532454-e138e28a63f4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&q=60"
                  }
                  hideExplore={hideExplore}
                ></SliderItem>
              ))}
            </Carousel>
          </div>

          {hideButtons ? null : (
            <div className="btn-wrapper ">
              <WipeBtnBlack
                text="All Product Development Services"
                url="/solutions/productdevelopment"
              ></WipeBtnBlack>

              <WipeBtnBlack
                text="All Productivity Services"
                url="/solutions/productivity"
              ></WipeBtnBlack>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default IndustrySlider;
